import { forwardRef, useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import type {
  GridEditSingleSelectCellProps,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-pro';
import {
  GridEditSingleSelectCell,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { isEmpty, isNil, isString } from 'lodash';

import { DomainId } from '@inspiren-monorepo/util-rooms';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useOrgRooms } from '../../../hooks/useOrgRooms';
import {
  getOptionLabel,
  getOptionValue,
} from '../../ImportTable/helpers/getOptionData';
import { useFieldValidator } from '../../ImportTable/hooks/useFieldValidator';

type ValueOption = {
  value: string;
  label: string;
};

type Props = GridRenderEditCellParams & {
  useDomainId?: boolean;
};

const filterRoom = (
  room: AdminTypes.Room,
  selectedOrg: string,
  selectedBuildingFloorUnit: string,
  useDomainId?: boolean,
): boolean => {
  if (useDomainId) {
    return (
      DomainId.toUnitId(room.domainId) ===
      `${selectedOrg}-${selectedBuildingFloorUnit}`
    );
  }

  return room.unitId === selectedBuildingFloorUnit;
};

const ImportRoomSelect = forwardRef((props: Props, ref) => {
  const apiRef = useGridApiContext();

  const { useDomainId, ...params } = props;
  const { id, error: validationError, disabled } = params;

  const selectedOrg = import.meta.env.VITE_ORG_ID;

  const selectedBuildingFloorUnit =
    apiRef.current?.state.editRows[id]?.buildingFloorUnit?.value ||
    apiRef.current?.state.editRows[id]?.unitId?.value;

  const { isLoading, rooms } = useOrgRooms({ orgId: selectedOrg });

  const valueOptions = useMemo(() => {
    if (isEmpty(selectedOrg) || isEmpty(selectedBuildingFloorUnit) || !rooms) {
      return [];
    }

    return (rooms || [])
      .filter((room) =>
        filterRoom(room, selectedOrg, selectedBuildingFloorUnit, useDomainId),
      )
      .map((room) =>
        useDomainId
          ? DomainId.parse(room.domainId).room || ''
          : {
              value: room.id,
              label: room.displayName || '',
            },
      )
      .sort((a, b) =>
        isString(a) && isString(b)
          ? a.localeCompare(b)
          : (a as ValueOption).label.localeCompare((b as ValueOption).label),
      );
  }, [rooms, selectedOrg, selectedBuildingFloorUnit]);

  const { invalidValueError } = useFieldValidator({
    skipValidation:
      isLoading || isNil(selectedOrg) || isNil(selectedBuildingFloorUnit),
    params,
    valueOptions,
  });

  const selectParams: GridEditSingleSelectCellProps = {
    ...params,
    colDef: {
      ...params.colDef,
      type: 'singleSelect',
      valueOptions,
      getOptionLabel,
      getOptionValue,
    },

    disabled:
      disabled || !selectedOrg || !selectedBuildingFloorUnit || isLoading,
    startAdornment: !selectedOrg && isLoading && (
      <CircularProgress color='secondary' size={18} />
    ),
    error: invalidValueError || !!validationError,
  };

  return <GridEditSingleSelectCell {...selectParams} ref={ref} />;
});

export default ImportRoomSelect;

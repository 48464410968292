import { useMemo } from 'react';
import { groupBy, isNil, keys, sortBy } from 'lodash';
import { useWatch } from 'react-hook-form';

import { sortByAlpha } from '@inspiren-monorepo/util-formatters';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useUnitOptions } from '../../../../../hooks/useUnitOptions';
import { useOrgRooms } from '../../../hooks/useOrgRooms';
import ControlledDropdown, {
  type Item,
} from '../../../modals/ControlledDropdown';

import type { Control, UseFormSetValue } from 'react-hook-form';

type Props = {
  control: Control;
  setValue: UseFormSetValue<
    Pick<Partial<AdminTypes.BasestationDto>, 'unitName' | 'roomId'>
  >;
};

const SelectUnitRoom = ({ control, setValue }: Props) => {
  const orgId = import.meta.env.VITE_ORG_ID;

  const selectedUnit = useWatch({
    name: 'unitId',
    control,
  });

  const { data: units } = useUnitOptions();

  const { isLoading: roomsLoading, rooms } = useOrgRooms({
    orgId,
  });

  const filteredRooms = useMemo(() => {
    const mappedRooms = rooms
      ?.filter((room) => room.unitId === selectedUnit && !room.deleted)
      .map((room) => ({
        label: room.displayName || '',
        value: room.id,
      }));

    return sortByAlpha(mappedRooms ?? [], 'label');
  }, [rooms, selectedUnit]);

  const handleUnitEdit = () => {
    setValue('roomId', '');
  };

  const unitItems = useMemo(() => {
    if (isNil(units)) return [];

    const unitsByBuilding = groupBy(units, 'buildingDisplayName');
    const buildings = keys(unitsByBuilding).sort();

    const items: Item[] = [];

    buildings.forEach((building) => {
      items.push({ groupLabel: building });

      sortBy(unitsByBuilding[building], 'displayName').forEach((unit) => {
        items.push({
          label:
            unit.displayName || (unit.displayName?.split('-').pop() as string),
          value: unit.id,
        });
      });
    });

    return items;
  }, [units]);

  return (
    <>
      <ControlledDropdown
        id='unitId'
        label='Unit'
        control={control}
        onChange={handleUnitEdit}
        items={unitItems}
      />
      <ControlledDropdown
        id='roomId'
        label='Room'
        items={filteredRooms}
        control={control}
        disabled={!orgId || !selectedUnit || roomsLoading}
      />
    </>
  );
};

export default SelectUnitRoom;

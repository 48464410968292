import { useMemo } from 'react';
import type {
  GridEditSingleSelectCellProps,
  GridRenderEditCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import {
  GridEditSingleSelectCell,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { isEmpty, isNil, sortBy } from 'lodash';

import { useUnits } from '../../../hooks/useUnits';
import { getOptionLabel, getOptionValue } from '../helpers/getOptionData';
import { useFieldValidator } from '../hooks/useFieldValidator';

type Props = GridRenderEditCellParams<GridValidRowModel, string> & {
  useDomainId?: boolean;
};

const ImportUnitSelect = (props: Props) => {
  const apiRef = useGridApiContext();

  const { useDomainId, ...params } = props;
  const { id, error: validationError, disabled } = params;

  const { isLoading, data: units } = useUnits();

  const selectedOrg = import.meta.env.VITE_ORG_ID;

  const valueOptions = useMemo(() => {
    if (isEmpty(selectedOrg) || !units) {
      return [];
    }

    return sortBy(
      units
        .filter((unit) => unit.orgId === selectedOrg)
        .map((unit) => ({
          label: unit.domainId.replace(`${selectedOrg}-`, ''),
          value: useDomainId
            ? unit.domainId.replace(`${selectedOrg}-`, '')
            : unit.id,
        })),
      'label',
    );
  }, [units, selectedOrg]);

  const { invalidValueError } = useFieldValidator({
    skipValidation: isLoading || isNil(selectedOrg),
    params,
    valueOptions,
  });

  const selectParams: GridEditSingleSelectCellProps = {
    ...params,
    colDef: {
      ...params.colDef,
      type: 'singleSelect',
      valueOptions,
      getOptionValue,
      getOptionLabel,
    },
    disabled: disabled || !selectedOrg || isLoading,
    onValueChange: () => {
      apiRef.current?.setEditCellValue({
        id,
        field: 'room',
        value: '',
        debounceMs: 200,
      });
    },
    error: !!selectedOrg && (invalidValueError || !!validationError),
  };

  return <GridEditSingleSelectCell {...selectParams} />;
};

export default ImportUnitSelect;

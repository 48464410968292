import ControlledCheckbox from '../ControlledCheckbox';
import ControlledDropdown from '../ControlledDropdown';
import ControlledMobilePhoneField from '../ControlledMobilePhoneField';
import ControlledPasswordInput from '../ControlledPasswordInput';
import ControlledTextField from '../ControlledTextField';
import ControlledTextFieldGroup from '../ControlledTextFieldGroup';

import type { DataField, FieldTypes } from '../../types/DataFields';
import type { Control, UseFormSetValue } from 'react-hook-form';

export const getDefaultComponentFromFieldData = <F extends FieldTypes>(
  dataField: DataField<F>,
  control: Control,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>,
  type: 'add' | 'edit',
) => {
  const {
    field,
    label,
    options,
    editType,
    editable = true,
    disabled = false,
    hideOnAdd = false,
    hideOnEdit = false,
    tooltip,
    properties,
  } = dataField;

  if (type === 'add' && hideOnAdd) return null;
  if (type === 'edit' && hideOnEdit) return null;

  const isDisabled = disabled || (editable === false && type === 'edit');

  switch (editType) {
    case 'mobile-phone':
      return (
        <ControlledMobilePhoneField
          key={field}
          id={field}
          label={label}
          control={control}
          disabled={isDisabled}
          tooltip={tooltip}
        />
      );
    case 'text-group':
      return (
        <ControlledTextFieldGroup
          key={field}
          id={field}
          label={label}
          control={control}
          setValue={setValue}
          disabled={isDisabled}
          tooltip={tooltip}
          properties={properties}
        />
      );
    case 'text':
      return (
        <ControlledTextField
          key={field}
          id={field}
          label={label}
          control={control}
          disabled={isDisabled}
          tooltip={tooltip}
        />
      );
    case 'password':
      return (
        <ControlledPasswordInput
          key={field}
          id={field}
          label={label}
          control={control}
        />
      );
    case 'select':
      return (
        <ControlledDropdown
          key={field}
          id={field}
          label={label}
          items={options || []}
          control={control}
          disabled={isDisabled}
          tooltip={tooltip}
        />
      );
    case 'boolean':
      return (
        <ControlledCheckbox
          key={field}
          id={field}
          label={label}
          control={control}
          tooltip={tooltip}
          disabled={isDisabled}
        />
      );
    default:
      return null;
  }
};

import { isEmpty } from 'lodash';
import * as yup from 'yup';

import { positiveInt } from '@inspiren-monorepo/shared-react/browser';
import { DomainId } from '@inspiren-monorepo/util-rooms';

import { hours } from '../../../../../utility/constants';
import { returnEscTooltipLabel } from '../../../modals/helpers/returnTooltipLabel';
import { formatEscalationValues } from '../helpers/formatEscalationValues';

import type { DataFields } from '../../../types/DataFields';
import type { UnitFieldTypes } from '../types/UnitFieldTypes';

const numberSchema = yup
  .number()
  .typeError('This setting must be a number')
  .min(0)
  .integer('This setting must be a whole number')
  // Empty string is also an acceptable value
  .transform((value, originalValue) => (originalValue === '' ? null : value))
  .nullable();

const escNumberSchema = yup.object({
  assigned: yup
    .number()
    .typeError('This setting must be a number')
    .integer('This setting must be a whole number')
    // Empty string is also an acceptable value
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable(),
  unit: yup
    .number()
    .typeError('This setting must be a number')
    .integer('This setting must be a whole number')
    // Empty string is also an acceptable value
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable(),
  building: yup
    .number()
    .typeError('This setting must be a number')
    .integer('This setting must be a whole number')
    // Empty string is also an acceptable value
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable(),
});

export const unitFields: DataFields<UnitFieldTypes> = [
  {
    field: 'id',
    label: 'ID',
    width: 'hidden',
  },
  {
    field: 'orgId',
    label: 'Organization',
    width: 150,
    schema: yup.string().required('You must select an organization'),
    initialValue: import.meta.env.VITE_ORG_ID,
  },
  {
    field: 'domainId',
    label: 'Domain ID',
    width: 'hidden',
  },
  {
    field: 'floorId',
    label: 'Building-Floor',
    width: 200,
    schema: yup.string().required('You must select a building and floor'),
    valueGetter: (_value, row) => DomainId.toBuildingFloor(row.domainId),
  },
  {
    field: 'name',
    label: 'ID',
    width: 150,
    editType: 'text',
    editable: false,
    schema: yup
      .string()
      .required('You must provide an ID')
      .matches(
        /^[\d.a-z]+$/i,
        'ID can only include letters, numbers, and periods.',
      ),
  },
  {
    field: 'displayName',
    label: 'Display name',
    width: 180,
    editType: 'text',
    initialValue: '',
    schema: yup.string().notRequired(),
  },
  {
    field: 'address',
    label: 'Address',
    width: 180,
    editType: 'text',
    disabled: true,
    hideOnAdd: true,
  },
  {
    field: 'virtualCurtain',
    label: 'Virtual Curtain',
    width: 160,
    type: 'boolean',
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'disableAugi',
    label: 'Disable AUGi',
    width: 160,
    type: 'boolean',
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'audibleMessages',
    label: 'Audible Messages',
    width: 160,
    type: 'boolean',
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'webFallAlertSoundLoop',
    label: 'Web Fall Alert Sound Loop',
    width: 200,
    type: 'boolean',
    editType: 'boolean',
    hideOnAdd: true,
    tooltip: 'Play alert sound until FALL notif expires or is resolved',
  },
  {
    field: 'nightLowFallRisk',
    label: 'Night Low Fall Risk',
    width: 160,
    type: 'boolean',
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'fallRiskReset',
    label: 'Reset Fall Risk',
    width: 160,
    type: 'boolean',
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'displayCareTeam',
    label: 'Display Care Team',
    width: 160,
    type: 'boolean',
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'hide',
    label: 'Hide',
    width: 160,
    type: 'boolean',
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'virtualCurtainDurationOptions',
    label: 'Virtual Curtain Duration Options',
    tooltip:
      'Select from a list of available durations in minutes for which the virtual curtain mode is activated. Type in a value (e.g., 2, 5, 10), press enter to save it, and repeat to add more. You can customize this list as needed.',
    width: 220,
    hideOnAdd: true,
    initialValue: [],
    valueFormatter: (value: string[]) =>
      value?.map((num: string) => `${num} min`).join(', '),
    schema: yup.array(numberSchema),
  },
  {
    field: 'warningThreshold',
    label: 'Daytime Warning Threshold',
    tooltip:
      'Set the time in minutes after which a warning is issued during daytime hours if a rounding is not completed. Example: Input 60 for a 1-hour threshold.',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
  },
  {
    field: 'nightWarningThreshold',
    label: 'Nighttime Warning Threshold',
    tooltip:
      'Set the time in minutes after which a warning is issued during nighttime hours if rounding is not completed. Example: Input 45 for a 45-minute threshold.',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
  },
  {
    field: 'roundingThreshold',
    label: 'Rounding Overdue Alert (Day)',
    tooltip:
      'Set the time in minutes after which an alert is triggered for an overdue rounding during daytime hours. Example: Input 90 to trigger an alert after 90 minutes.',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
  },
  {
    field: 'nightModeThreshold',
    label: 'Rounding Overdue Alert (Night)',
    tooltip:
      'Set the time in minutes after which an alert is triggered for an overdue rounding during nighttime hours. Example: Input 120 to trigger an alert after 120 minutes.',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
  },
  {
    field: 'nightStartHour',
    label: 'Night Mode Start Time',
    tooltip:
      'Specify the time when night mode begins (12-hour format). Example: Input 11:00 PM for night mode to start at 11 PM.',
    width: 200,
    editType: 'select',
    hideOnAdd: true,
    options: hours,
    valueFormatter: (value) =>
      hours.find((hour) => hour.value === value)?.label,
  },
  {
    field: 'nightEndHour',
    label: 'Night Mode End Time',
    tooltip:
      'Specify the time when night mode ends (12-hour format). Example: Input 7:00 AM for night mode to end at 7 AM.',
    width: 200,
    editType: 'select',
    hideOnAdd: true,
    options: hours,
    valueFormatter: (value) =>
      hours.find((hour) => hour.value === value)?.label,
  },
  {
    field: 'imageExp',
    label: 'Image Retention Period',
    tooltip:
      'Set the duration in days for which images are stored before they are automatically deleted. Example: Input 30 to retain images for 30 days.',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
    valueFormatter: (value) => value && `${value} days`,
  },
  {
    field: 'eventExp',
    label: 'Event Log Retention Period',
    tooltip:
      'Set the duration in days for which event logs are stored before they are automatically deleted. Example: Input 90 to retain logs for 90 days.',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
    valueFormatter: (value) => value && `${value} days`,
  },
  {
    field: 'escalateFall',
    label: 'Fall Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escalateFall'),
    valueFormatter: (value) => formatEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escalateLB',
    label: 'Leaving Bed Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escalateLB'),
    valueFormatter: (value) => formatEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escalateOOC',
    label: 'Out Of Chair Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escalateOOC'),
    valueFormatter: (value) => formatEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escalateHidden',
    label: 'Hidden Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escalateHidden'),
    valueFormatter: (value) => formatEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escalateUrgent',
    label: 'Urgent Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escalateUrgent'),
    valueFormatter: (value) => formatEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escalateWarning',
    label: 'Warning Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escalateWarning'),
    valueFormatter: (value) => formatEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escalateBathroom',
    label: 'Bathroom Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escalateBathroom'),
    valueFormatter: (value) => formatEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escalateUnit',
    label: 'Listen To Other Unit Escalations',
    width: 225,
    type: 'boolean',
    editType: 'boolean',
    hideOnAdd: true,
    tooltip: returnEscTooltipLabel('escalateUnit'),
  },
  {
    field: 'disableAugiAlertInterval',
    label: 'Disable AUGi Alert Interval',
    width: 225,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
    valueFormatter: (value) => (value ? `${value} hours` : 'Off'),
    tooltip:
      'Number of hours between mobile app reminder alerts for disabled AUGis. 0 or blank to turn off reminders.',
  },
  {
    field: 'bathroomAlertThreshold',
    label: 'Bathroom Alert Threshold',
    width: 225,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
    valueFormatter: (value) => (!isEmpty(value) ? `${value} minutes` : 'Off'),
    tooltip:
      'Number of minutes after which to send an alert if the bathroom is occupied. 0 and alert will be sent immediately. Leave blank and no alert will be sent.',
  },
  {
    field: 'pccUnitId',
    label: 'PCC Unit ID',
    width: 'hidden',
    editType: 'text',
    schema: positiveInt({ nullable: true }),
  },
  {
    field: 'alisUnitId',
    label: 'Alis Unit ID',
    width: 'hidden',
    editType: 'text',
    schema: positiveInt({ nullable: true }),
  },
];
